export const ShopStatus = {
  ACTIVE: {value: 'active', display: 'Активный'},
  DELETED: {value: 'deleted', display: 'Удалено'}
}
export const UserRole = {
  ADMIN: {value: 'admin', display: 'Админ'},
  CLIENT: {value: 'client', display: 'Клиент'}
}
export const CustomerRole = {
  BUYER: {value: 'buyer', display: 'Покупатель'},
  SELLER: {value: 'seller', display: 'Продавец'}
}
export const Gender = {
  MALE: {value: 'male', display: 'Мужской', isChecked: false},
  FEMALE: {value: 'female', display: 'Женский', isChecked: false},
  OTHER: {value: 'other', display: 'Все', isChecked: false}
}

export const GenderFilter = {
  MALE: {value: 'male', display: 'Мужской', isChecked: false},
  FEMALE: {value: 'female', display: 'Женский', isChecked: false},
  OTHER: {value: null, display: 'Все', isChecked: false},

}

export const Rating = {
  Rating5: {value: '4.5,5', display: 'только 5'},
  Rating4: {value: '3.5,5', display: '4 и выше'},
  Rating3: {value: '2.5,5', display: '3 и выше'},
  Rating2: {value: '1.5,5', display: '2 и выше'},
  Rating1: {value: '0.5,5', display: '1 и выше'},
}

export const FilterOperator = {
  BTW: {value: '$btw:'},
  OR: {value: '$or:'},
  AND: {value: '$and:'},
}
export const UserStatus = {
  ACTIVE: {value: 'active', display: 'Активный'},
  PENDING: {value: 'pending', display: 'В ожидании'},
  DELETED: {value: 'deleted', display: 'Удалено'}
}

export const Currency = {
  // AMD: {value: 'amd', display:'AMD'},
  RUB: {value: 'rub', display: 'RUB'},
  // USD: {value: 'usd', display:'USD'},
  // EUR: {value: 'eur', display:'EUR'},
}

export const TransactionType = {
  TOP_UP: {value: 'top_up', display: 'TOP UP'},
  WITHDRAW: {value: 'withdraw', display: 'WITHDRAW'}
}

export const NotificationType = {
  STANDARD: {value: 'standard', display: 'STANDARD'},
  ORDER_LINK: {value: 'orderLink', display: 'ORDER_LINK'},
  REQUEST: {value: 'request', display: 'REQUEST'}
}

export const NotificationStatus = {
  PENDING: {value: 'pending', display: 'PENDING'},
  SEEN: {value: 'seen', display: 'SEEN'},
  NOT_SEEN: {value: 'not_seen', display: 'NOT_SEEN'},
  DELETED: {value: 'deleted', display: 'DELETED'},
  ACCEPTED: {value: 'accepted', display: 'ACCEPTED'},
  CANCELED: {value: 'canceled', display: 'CANCELED'}
}
export const EVENTTYPES = {
  USER: 'user',
  TASK_UPDATE: 'task_update',
  EVENT: 'event',
  COMMENT: 'comment',
  CHATROOM: 'chat',
};
export const ACTIONTYPES = {
  ADD: "ADD",
  UPDATE: "UPDATE",
  DELETE: "DELETE"
}

// Angular import
import { Component, Input } from '@angular/core';
import {CurrencyPipe, NgClass, NgForOf, NgIf} from '@angular/common';
import {Router, RouterModule} from "@angular/router";
import {EventManagerService} from "../../../../services/helpers/event-manager";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {AnnouncementService} from "../../../../services/services/announcement/announcement.service";
import {StorageService} from "../../../../services/helpers/storage.service";
import {MatButtonModule} from "@angular/material/button";
import {MatDividerModule} from "@angular/material/divider";
import {MatMenuModule} from "@angular/material/menu";
import {NgScrollbar} from "ngx-scrollbar";
import {SharedModule} from "../../../../shared/shared.module";
import {UserWithdrawComponent} from "../../../../pages/admin/user/user-list/user-withdraw/user-withdraw.component";
import {UserTopupComponent} from "../../../../pages/admin/user/user-list/user-topup/user-topup.component";
import {AuthenticationService} from "../../../../services/services/authentication.service";
import {WithdrawService} from "../../../../services/services/transaction/withdraw.service";
import {UserService} from "../../../../services/services/user/user.service";
import {MeService} from "../../../../services/services/user/me.service";
import {PopupHelper} from "../../../../services/helpers/popup.helper";
import {MatDialog} from "@angular/material/dialog";
import {NavBarModule} from "../../../admin/toolbar/toolbar.module";
import {ToolbarRightComponent} from "../toolbar-right/toolbar-right.component";

@Component({
  selector: 'app-header-client',
  templateUrl: './header.component.html',
  standalone: true,
  imports: [RouterModule, NgIf, TranslateModule, NgForOf, NgClass, MatButtonModule, MatDividerModule, MatMenuModule, NgScrollbar, SharedModule, NavBarModule, ToolbarRightComponent, CurrencyPipe],
  providers: [AnnouncementService],
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() user: any;
  suggestions = [];
  openComplete = false;
  inProgressCall:any;
  client: any;
  constructor(
    private eventManagerService:EventManagerService,
    private announcementService: AnnouncementService,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private withdrawService: WithdrawService,
    private userService: UserService,
    private meService: MeService,
    private popup: PopupHelper,
    public dialog: MatDialog,
    private router: Router,
  ) {
    this.client = StorageService.getJsonItem(StorageService.statics.currentUser);
  }

  openFilter() {
    this.eventManagerService.openFilterCategory()
  }

  showDialog(){
    this.openComplete = true;
  }

  findAndSearch(event: any){
    this.router.navigate(['/buyer-landing-buyer/announcements'],
      {queryParams:{'search' : event.target.value}}
    )
    this.closeSearch();
  }

  onInput(event: any) {
    const query = event.target.value;
    if(this?.inProgressCall){
      //this.inProgressCall?.unsubscribe();
    }
    this.inProgressCall = this.announcementService.getItems({limit:10, page:1}, {searchBy:'product.name', search:query});
    setTimeout(()=>{
      this.inProgressCall.subscribe((items:any) => {
        this.inProgressCall = null;
        this.suggestions = items?.data.data
        this.openComplete = true;

      }, ()=>{
        this.inProgressCall = null;
      });
    },300)
  }

  closeSearch(){
    setTimeout(()=>{
      this.openComplete = false;
    }, 100)
  }



  useLanguage(language: string) {
    this.translate.use(language);
  }


  logout() {
    this.authenticationService.logout().subscribe((res) => {
      if (!res.hasError) {
        StorageService.clear()
        this.router.navigate(['/auth/login'])
      }
    });
  }


  redirectToChat() {
    this.router.navigate(['/messenger'])

  }
  openBalanceDetails(): void {
    const dialogRef = this.dialog.open(UserWithdrawComponent, {
      data: {
        user:this.client
      },
      width: '600px'
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res.provider && (res.accountNumber && res.balance !== 0)) {
        this.withdrawService.newWithdraw({
          "amount": res.balance,
          "provider": res.provider,
          "accountNumber": res.accountNumber
        }).subscribe( e => {
          if (!e.hasError) {
            // // this.popup.printSuccess()
          }
        })
      }
    })
  }  // public method

  openTopup(): void {
    const dialogRef = this.dialog.open(UserTopupComponent, {
      data: {
        user: this.client
      },
      width: '600px'
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res?.userId && (res?.balance || res?.balance === 0)) {
        this.userService.updateBlance({balance:res.balance}, res.userId).subscribe( e => {
          if (!e.hasError) {
            // // this.popup.printSuccess()
          }
        })
      }
    })
  }

  changeCustomerRole(role:string) {
    this.meService.updateCustomerRole({"customerRole": role}).subscribe( res => {
      if (!res.hasError) {
        this.client.customerRole = role;
        StorageService.setJsonItem(StorageService.statics.currentUser,this.client)

        this.meService.me().subscribe((data:any) => {
          if(!data.hasError){
            this.client = data.data.user;
            StorageService.setJsonItem(StorageService.statics.profileData, data.data.user);
            StorageService.setJsonItem(StorageService.statics.initialInfo, data.data);
            this.eventManagerService.userDataUpdate.emit(data.data.user)

            this.eventManagerService.notificationsCountEmmiter.emit(data.data.notSeenNotificationCount);
            this.eventManagerService.messagesCountEmmiter.emit(data.data.notSeenRooms);
          }
        })
      }else {
        this.popup.printError(res.message)
      }

    })

  }

}

import {Navigation} from 'src/app/services/types/navigation';

export const menus: Navigation[] = [
  {
    id: 'admin-only',
    title: 'Только администратор',
    type: 'group',
    icon: 'icon-navigation',
    roles: [
      'admin'
    ],
    children: [
      {
        id: 'navigation',
        title: 'CRM',
        type: 'collapse',
        icon: 'fa fa-users',
        iconTag: true,
        children: [
          {
            id: 'Users',
            title: 'Пользователи',
            type: 'item',
            classes: 'nav-item',
            url: '/admin/users',
            children: [
              {
                id: 'add',
                title: 'Добавить пользователя',
                type: 'item',
                url: '/admin/users/add'
              },
              {
                id: 'edit',
                title: 'Изменить пользователя',
                type: 'item',
                url: '/admin/users/edit/:id'
              },
            ]
          }
        ]
      },
      {
        id: 'navigation',
        title: 'Обшие настройки',
        type: 'collapse',
        icon: 'fa fa-cog',
        iconTag: true,
        children: [
          {
            id: 'Category',
            title: 'Категории',
            iconTag: true,
            type: 'item',
            classes: 'nav-item',
            url: "/admin/category",
            children: [
              {
                id: 'Category',
                title: 'Добавить категорию',
                type: 'item',
                icon: '#custom-status-up',
                url: "/admin/category/add",
              },
              {
                id: 'Category',
                title: 'Изменить категорию',
                type: 'item',
                icon: '#custom-status-up',
                url: "/admin/category/edit/:id",
              },
            ]
          },
          {
            id: 'Shop',
            title: 'Магазины',
            type: 'item',
            classes: 'nav-item',
            url: "/admin/shop",
            children: [
              {
                id: 'Shop',
                title: 'Добавить магазин',
                type: 'item',
                icon: '#custom-status-up',
                url: "/admin/shop/add",
              },
              {
                id: 'Shop',
                title: 'Изменить магазин',
                type: 'item',
                icon: '#custom-status-up',
                url: "/admin/shop/edit/:id",
              },
            ]
          },

          {
            id: 'Task Template',
            title: 'Шаблоны задач',
            type: 'item',
            classes: 'nav-item',
            url: "/admin/task/taskTemplate",
            children: [
              {
                id: 'Task Template',
                title: 'Добавить шаблон заданий',
                type: 'item',
                icon: '#custom-status-up',
                url: "/admin/taskTemplate/add",
              },
              {
                id: 'Task Template',
                title: 'Изменить шаблон',
                type: 'item',
                icon: '#custom-status-up',
                url: "/admin/taskTemplate/edit/:id",
              },
            ]
          },
          {
            id: 'taskType',
            title: 'Типы задач',
            type: 'item',
            classes: 'nav-item',
            url: "/admin/task/taskType",
            children: [
              {
                id: 'Task Template',
                title: 'Добавить тип задачи',
                type: 'item',
                icon: '#custom-status-up',
                url: "/admin/taskTemplate/add",
              },
              {
                id: 'Task Template',
                title: 'Изменить тип задачи',
                type: 'item',
                icon: '#custom-status-up',
                url: "/admin/taskTemplate/edit/:id",
              },
            ]
          },
        ]
      },
      {
        id: 'navigation',
        title: 'Продукты и задачи',
        type: 'collapse',
        icon: 'fa fa-tasks',
        iconTag: true,
        children: [
          {
            id: 'announcement',
            title: 'Управление обявлениями',
            type: 'item',
            iconTag: true,
            classes: 'nav-item',
            url: '/admin/announcement',
            roles: [
              'admin'
            ]
          },
          {
            id: 'orders',
            title: 'Заказы',
            type: 'item',
            iconTag: true,
            classes: 'nav-item',
            url: '/admin/order/list',
            roles: [
              'admin'
            ]
          },
        ]
      },
      {
        id: 'navigation',
        title: 'Финансы',
        type: 'collapse',
        icon: 'fa fa-dollar-sign',
        iconTag: true,
        children: [
          {
            id: 'Transaction',
            title: 'Транзакции',
            type: 'item',
            classes: 'nav-item',
            url: "/admin/transaction",
          },
          {
            id: 'Withdraw',
            title: 'Вывод',
            type: 'item',
            classes: 'nav-item',
            roles: [
              'admin'
            ],
            url: "/admin/withdraw"
          }
        ]
      },
    ]
  },
  // {
  //   id: 'application',
  //   title: 'Продукты и задачи',
  //   type: 'group',
  //   icon: 'icon-navigation',
  //   roles: [
  //     'client',
  //     'admin',
  //     'seller',
  //     'buyer'
  //   ],
  //   children: [
  //       {
  //         id: 'product',
  //         title: 'Продукты',
  //         classes: 'nav-item',
  //         type: 'item',
  //         url: '/buyer-landing-buyer/product',
  //         roles: [
  //           'seller',
  //           'admin'
  //         ],
  //         children: [
  //           {
  //             id: 'new-product',
  //             title: 'Добавить новый продукт',
  //             type: 'item',
  //             url: '/e-commerce/new-product',
  //           },
  //           {
  //             id: 'new-product',
  //             title: 'Изменить продукт',
  //             type: 'item',
  //             url: '/e-commerce/edit/:id',
  //           },
  //         ],
  //       },
  //       {
  //         id: 'annoucmentt',
  //         title: 'Обявлении',
  //         type: 'item',
  //         iconTag: true,
  //         url: '/e-commerce/annoucmentt',
  //         classes: 'nav-item',
  //         roles: [
  //           'admin',
  //           'buyer'
  //         ],
  //         children: [
  //           {
  //             id: 'add announcement',
  //             title: 'Добавиить обявление',
  //             type: 'item',
  //             url: '/e-commerce/my-product/:productId/announcement/add/',
  //           },
  //           {
  //             id: 'Edit announcement',
  //             title: 'Изменить обявление',
  //             type: 'item',
  //             url: '/e-commerce/announcement/edite/:id/',
  //           }
  //           ]
  //       },
  //       {
  //         id: 'My Announcement',
  //         title: 'Мои обявлении',
  //         type: 'item',
  //         iconTag: true,
  //         url: '/e-commerce/my-announcement',
  //         classes: 'nav-item',
  //         roles: [
  //           'admin',
  //           'seller'
  //         ],
  //       },
  //       {
  //         id: 'order',
  //         title: 'Задачи',
  //         type: 'item',
  //         url: '/order/list',
  //         classes: 'nav-item',
  //         roles: [
  //           'client',
  //           'admin'
  //         ],
  //       },
  //   ]
  // },
  // {
  //   id: 'navigation',
  //   title: 'Транзакции',
  //   type: 'item',
  //   classes: 'nav-item',
  //   url: "/transaction",
  //
  // },

]

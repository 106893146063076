<nav class="bg-gray-800 z-10">
  <div class="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
    <div class="relative flex h-16 items-center justify-between">
      <div class="flex items-center px-2 lg:px-0">
        <div class="flex-shrink-0">
          <a routerLink="/">
            <img class="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500" alt="TumpTump">
          </a>
        </div>
        <div class="hidden md:ml-6 md:block">
          <div class="flex space-x-4" *ngIf="!client?.customerRole">
            <a [routerLink]="['/landing-seller']" class="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white">{{"for_publishers" | translate}}</a>
            <a [routerLink]="['/']" class="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white">{{"for_buyers" | translate}}</a>
          </div>
          <div *ngIf="client?.customerRole === 'buyer'" class="flex space-x-4">
            <a [routerLink]="['/buyer-landing-buyer']" class="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white">{{"announcements" | translate}}</a>
            <a [routerLink]="['/buyer-landing-buyer/sellers']" class="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white">{{"sellers" | translate}}</a>
          </div>
          <div *ngIf="client?.customerRole === 'seller'" class="flex space-x-4">
            <a [routerLink]="['/seller-landing-buyer']" class="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white">{{"dashboard" | translate}}</a>
            <a [routerLink]="['/seller-landing-buyer/buyers']" class="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white">{{"freelancers" | translate}}</a>
          </div>

        </div>
      </div>
      <div class="flex flex-1 justify-center px-2 lg:ml-6">
        <div class="w-full ">
          <label for="search" class="sr-only">{{'search'| translate}}</label>
          <div class="relative">
            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clip-rule="evenodd" />
              </svg>
            </div>
            <input id="search" name="search"
                   (input)="onInput($event)"
                   (keydown.enter)="findAndSearch($event)"
                   (blur)="closeSearch()"
                   (click)="showDialog()"
                   class="block w-full rounded-md border-0 bg-gray-700 py-1.5 pl-10 pr-3 text-gray-300 placeholder:text-gray-400 focus:bg-white focus:text-gray-900 focus:ring-0 sm:text-sm sm:leading-6" [placeholder]="'search'| translate" type="search">
            <div class="results-field -top-2">
              <ul role="list" class="divide-y divide-gray-100 bg-white suggested-places z-20 " [ngClass]="{'open':openComplete}">
                <li *ngIf="suggestions.length === 0" class="flex px-2 justify-between gap-x-6 py-5">
                  {{'nothing_found' | translate}}
                </li>
                <li [routerLink]="['/buyer-landing-buyer/announcement/details', suggestion.id]"  (click)="closeSearch()" class="flex px-2 justify-between gap-x-6 py-5" *ngFor="let suggestion of suggestions">
                  <div class="flex min-w-0 gap-x-4">
                    <img class="h-12 w-12 flex-none rounded-full bg-gray-50 truncate" [src]="suggestion.product?.image"/>
                    <div class="min-w-0 flex-auto">
                      <p class="text-sm font-semibold leading-6 text-gray-900 truncate">{{ suggestion.product?.name }}</p>
                      <p class="mt-1 truncate text-xs leading-5 text-gray-500">
                        {{suggestion?.account?.nickName}}
                      </p>
                    </div>
                  </div>
                  <div class="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                    <p *ngIf="suggestion.maximalEarning" class="items-center rounded-full px-1.5 py-0.5 text-xs font-medium bg-green-50 text-green-700 ring-green-600/20 ring-1 ring-inset">
                        {{'earn' | translate}}: +{{ suggestion.maximalEarning | roundCurrency: suggestion.product?.currency }} руб.
                      </p>
                    <p *ngIf="suggestion.cashbackAmount" class="items-center rounded-full px-1.5 py-0.5 text-xs font-medium bg-red-50 text-red-700 ring-red-600/20 ring-1 ring-inset">
                        {{'cashback'| translate}}: +{{ suggestion.cashbackAmount | roundCurrency : suggestion.product?.currency }} руб.
                    </p>
                  </div>
                </li>
              </ul>
                  </div>
          </div>
        </div>
        <div class="lg:flex xl:flex max-md:hidden" *ngIf="client?.customerRole === 'buyer'">
          <!-- Mobile menu button -->
          <button type="button" (click)="openFilter()" class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400  hover:text-white focus:outline-none " aria-controls="mobile-menu" aria-expanded="false">
            <span class="absolute -inset-0.5"></span>
            <span class="sr-only">{{'open_menu'| translate}}</span>
            <!--
              Icon when menu is closed.

              Menu open: "hidden", Menu closed: "block"
            -->
            <svg class="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
            <!--
              Icon when menu is open.

              Menu open: "block", Menu closed: "hidden"
            -->
            <svg class="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
      <div class="flex" *ngIf="!client">
        <div class="lg:flex max-md:hidden">
          <!-- Mobile menu button -->
          <button type="button" routerLink="auth/login" class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
            {{'login'| translate}} <i class="ml-1.5 fa fa-arrow-right"></i>
          </button>
        </div>
      </div>
      <div class="lg:flex xl:flex  max-md:hidden max-sm:hidden" *ngIf="client">
        <div class="flex-shrink-0 lg:hidden md:hidden xl:inline-block mr-2">
          <button *ngIf="client?.customerRole === 'seller'" [routerLink]="['/seller-landing-buyer/new-announcement']" type="button" class="relative inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <svg class="-ml-0.5 h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
            </svg>
            <span class="md:hidden sm:hidden xl:inline-block ">
              {{'add_product'| translate}}
            </span>

          </button>
        </div>
        <app-toolbar-right></app-toolbar-right>

      </div>
    </div>
  </div>

</nav>



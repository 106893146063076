<div class="header-left_block">
<!--  <img src="favicon.ico" *ngIf="user.role === 'client'" class="image-logo"/>-->
  <div class="left-block_element" *ngIf="user && user.role === 'client' && user.customerRole === 'seller'">
    <a class="stat-menu"  [routerLink]="['/seller-landing-buyer/products']" [routerLinkActive]="['active']" >
      <span>Обявление</span>
    </a>
  </div>

  <div class="left-block_element" *ngIf="!user || ( user.role === 'client' && user.customerRole === 'buyer')">
    <a class=" stat-menu" [routerLink]="['/buyer-landing-buyer/announcements']"  routerLinkActive="active"  >
      <span>Обявлении</span>
    </a>
  </div>


  <div class="left-block_element" *ngIf="user && user.role === 'client' && user.customerRole === 'seller'" >
    <a class=" stat-menu" [routerLink]="['/seller-landing-buyer/buyers']" routerLinkActive="active" >
<!--      class="block-element_title badge-on-button"-->
      <span>Фрилансеры</span>
    </a>
  </div>

  <div class="left-block_element" *ngIf="user && user.role === 'client' && user.customerRole === 'buyer'" >
    <a  class=" stat-menu" [routerLink]="['/buyer-landing-buyer/sellers']" routerLinkActive="active">
      <span>Заказчики</span>
    </a>
  </div>



<!--  <button *ngIf="user.role === 'client' && user.customerRole === 'buyer'" mat-button aria-label="mode" class="header-item">-->
<!--    <a [routerLink]="['/order/list']" routerLinkActive="active" class="title-for-big badge-on-button" matBadgeColor="primary" matBadgeOverlap="false">-->
<!--      <span>Мои задачи</span>-->
<!--    </a>-->
<!--  </button>-->

<!--  <button *ngIf="user.role === 'client' && user.customerRole === 'seller'" mat-button aria-label="mode" class="header-item">-->
<!--    <a [routerLink]="['/order/list']" routerLinkActive="active" class="title-for-big badge-on-button" matBadgeColor="primary" matBadgeOverlap="false">-->
<!--      <span>Заказы на проверку</span>-->
<!--    </a>-->
<!--  </button>-->

</div>



export const environment = {
  production: false,
  apiURL: 'https://devapi.poppuck-shop.online/api/',
  filePath: 'https://devapi.poppuck-shop.online/',
  socketUrl: 'https://devapi.poppuck-shop.online',
  //
  // apiURL: 'http://localhost:3000/api/',
  // filePath: 'http://localhost:3000/',
  // socketUrl: 'http://localhost:3000/',
};

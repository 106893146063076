import {AfterViewInit, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CommonModule,} from "@angular/common";
import {SharedModule} from "../../shared.module";
import {NarikCustomValidatorsModule} from "@narik/custom-validators";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {FilterOperator, GenderFilter, Rating} from "../../../services/constants";
import {CategoryService} from "../../../services/services/category/category.service";
import convertForSelectBox from "../../../services/helpers/convert-for-selectBox";
import {CategoryComponent} from "../category/category.component";
import {FilterCategoryComponent} from "../filter-category/filter-category.component";

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule, NarikCustomValidatorsModule, RouterLink, CategoryComponent, FilterCategoryComponent],
  providers: [CategoryService,],

})

export class FilterComponent implements OnInit, AfterViewInit {
  @Output() filter: EventEmitter<any> = new EventEmitter<any>()

  genderSelectBox = convertForSelectBox(GenderFilter);
  reatingSelectBox = convertForSelectBox(Rating)
  public pageFilter: any = null
  protected readonly FilterOperator = FilterOperator;

  constructor(private route: ActivatedRoute) {

  }

  ngOnInit() {

  }
  ngAfterViewInit() {
    this.route.queryParams
      .subscribe((params: any) => {
        if (params && Object.keys(params).length >= 4 ) {
          if ((params.eventChecked || params.eventChecked === false) && params.eventSource && params.filterOperator && params.operators) {
            this.input({checked:params.eventChecked,source: {value:params.eventSource}},  params.filterOperator, params.operators)
          }
        }
      })
  }


  input(event: any, filterOperator: any, operators: string[]) {

    this.pageFilter = {...this.pageFilter};

    let currentObject = this.pageFilter;
    for (let i = 0; i < operators.length; i++) {
      const key = operators[i];
      currentObject[key] = currentObject[key] || {};

      if (i === operators.length - 1) {
        currentObject[key] = [];
        if (Array.isArray(currentObject[key])) {
          if (event.checked === false) {
            // @ts-ignore
            currentObject[key] = currentObject[key].filter(item => item !== filterOperator + event.source.value);
          } else if (event.value) {
            currentObject[key].push(filterOperator + event.source.value);
          }
        } else {
          if (event.value){
            currentObject[key] = [filterOperator + event.source.value];
          }
        }
      }

      currentObject = currentObject[key];
    }
    this.filter.emit(this.pageFilter)
  }


}


















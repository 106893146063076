import {Component, Inject, OnInit} from '@angular/core';
import {RouterLink} from "@angular/router";
import {CommonModule, Location} from "@angular/common";
import {SharedModule} from "../../../../../shared/shared.module";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {environment} from "../../../../../../environments/environment";
import {UserListComponent} from "../user-list.component";

@Component({
  selector: 'app-user-withdraw',
  standalone: true,
  imports: [CommonModule, SharedModule, RouterLink],
  templateUrl: './user-withdraw.component.html',
  styleUrls: ['./user-withdraw.component.scss']
})
export class UserWithdrawComponent implements OnInit {
  selected = '1';
  public itemId: string | null;
  private userData: any;
  private response:string;
  public accountNumber: any = '';
  public balance:number = 0;
  imgUrl: any
  user:any


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<UserListComponent>,) {

  }
  ngOnInit() {
    this.balance = this.data.user.balance
    this.imgUrl = environment.filePath

  }
  save() {
    this.dialogRef.close({
      provider:'qiwi',
      accountNumber:this.data.user.id,
      balance: this.balance,
    })
  }

  protected readonly close = close;
}


